<!-- eslint-disable no-plusplus -->
<template>
  <div id="user-list">
    <!-- app drawer -->
    <user-list-add-new
      v-model="isAddNewUserSidebarActive"
      :login-methods="loginMethods"
      @refetch-data="fetchUsers"
    ></user-list-add-new>

    <!-- table -->
    <v-card>
      <v-card-title>
        Leerlingen
      </v-card-title>

      <!-- Actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search name -->
        <v-text-field
          v-model="filterName"
          placeholder="Zoeken"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <!-- filter class -->
        <v-select
          v-model="filterClass"
          placeholder="Kies klas"
          :items="classes"
          item-text="title"
          item-value="value"
          outlined
          clearable
          dense
          hide-details
          class="user-search me-3 mb-4"
          no-data-text="Geen klassen gevonden"
          multiple
        ></v-select>

        <v-spacer></v-spacer>

        <!-- Conditional action buttons (QR codes, labels, delete pupils, add pupil) -->
        <div class="d-flex align-center flex-wrap">
          <!-- Print qr codes button -->
          <v-btn
            v-if="selectedRows.length > 0"
            color="primary"
            outlined
            class="mb-4 mr-4"
            @click="printQrCodes"
          >
            <v-icon>{{ icons.mdiQrcode }}</v-icon>
            <span class="ms-2">QR-codes</span>
          </v-btn>

          <!-- Print labels button -->
          <v-btn
            v-if="selectedRows.length > 0"
            color="primary"
            outlined
            class="mb-4 mr-4"
            @click="stickerPrinting.showingWebsiteSelectDialog = true"
          >
            <v-icon>{{ icons.mdiStickerTextOutline }}</v-icon>
            <span class="ms-2">Stickers</span>
          </v-btn>

          <!-- Delete pupils button -->
          <v-btn
            v-if="selectedRows.length > 0"
            color="error"
            outlined
            class="mb-4 mr-4"
            @click="deletePupilsData.showingConfirmDialog = true"
          >
            <v-icon>{{ icons.mdiAccountMultipleRemove }}</v-icon>

            <span class="ms-2">Verwijderen</span>
          </v-btn>

          <!-- Add pupil button -->
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Leerling toevoegen</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="filteredPupils"
        :loading="loading"
        loading-text="Leerlingen aan het laden..."
        no-data-text="Geen leerlingen gevonden"
        show-select
      >
        <!-- name -->
        <template #[`item.firstName`]="{item}">
          <div class="d-flex align-center">
            <!-- Avatar -->
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text"
              size="32"
            >
              <span
                class="font-weight-semibold"
              >{{ avatarText(item.firstName + ' ' + item.lastName.split(' ')[0]) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'users-pupils-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.firstName + ' ' + item.lastName }}
              </router-link>
              <small>@{{ item.firstName }}</small>
            </div>
          </div>
        </template>

        <!-- Class -->
        <template #[`item.class`]="{item}">
          <span
            v-if="item.class"
          >
            {{ item.class.name }}
          </span>
          <span
            v-else
          >
            Geen
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'users-pupils-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="onBtnDeletePupilClick(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Verwijderen</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <!-- Stickers dialog (indicate the websites you want to print the stickers from) -->
    <v-dialog
      v-if="stickerPrinting.showingWebsiteSelectDialog"
      v-model="stickerPrinting.showingWebsiteSelectDialog"
      width="700"
    >
      <v-card>
        <v-card-title>
          Stickers afdrukken
        </v-card-title>

        <v-card-text>
          Van welke websites wens je de stickers af te drukken?
        </v-card-text>
        <v-card-text>
          (Je kunt gerust een website selecteren, ook al zijn er geen inloggegevens voor)
        </v-card-text>

        <v-card-text class="mt-5">
          <!-- Print labels button -->
          <v-btn
            color="primary"
            outlined
            class="mb-4 mr-4"
            @click="stickerPrinting.selectedWebsites = stickerPrinting.websites.map(website => website.easyId)"
          >
            <span>Alles</span>
          </v-btn>
          <v-form class="multi-col-validation">
            <v-row

              class="my-0"
            >
              <v-col
                v-for="website in stickerPrinting.websites"
                :key="website.easyId"
                cols="12"
                sm="6"
                md="6"
                lg="4"
                class="py-0"
              >
                <v-checkbox
                  v-model="stickerPrinting.selectedWebsites"
                  class="my-0"
                  :value="website.easyId"
                  :label="website.name"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            @click="stickerPrinting.showingWebsiteSelectDialog = false"
          >
            Annuleren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="printLabels"
          >
            Afdrukken
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      v-if="deletePupil.showDeleteDialog"
      v-model="deletePupil.showDeleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Leerling verwijderen
        </v-card-title>

        <v-card-text>
          Ben je zeker dat je {{ deletePupil.pupilToDelete.firstName }} {{ deletePupil.pupilToDelete.lastName }} wil verwijderen? Deze actie kan niet ongedaan gemaakt worden. Ook alle gekoppelde inloggegevens zullen hiermee verwijderd worden.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            @click="deletePupil.showDeleteDialog = false"
          >
            Annuleren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="onBtnDeletePupilConfirm"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete multiple pupils dialog -->
    <v-dialog
      v-if="deletePupilsData.showingConfirmDialog"
      v-model="deletePupilsData.showingConfirmDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          Leerlingen verwijderen
        </v-card-title>

        <v-card-text v-if="deletePupilsData.loading">
          <v-progress-linear
            v-model="deletePupilsData.progress.percent"
            height="25"
            rounded
            striped
          >
            <strong>{{ deletePupilsData.progress.current }} van {{ deletePupilsData.progress.total }}</strong>
          </v-progress-linear>
        </v-card-text>

        <v-card-text v-if="deletePupilsData.loading">
          Een momentje aub.. De leerlingen worden verwijderd...
        </v-card-text>
        <v-card-text v-else>
          Ben je zeker dat je deze {{ selectedRows.length > 1 ? selectedRows.length : '' }} leerling{{ selectedRows.length > 1 ? 'en' : '' }} wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden. Ook alle gekoppelde inloggegevens zullen hiermee verwijderd worden.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="secondary"
            :disabled="deletePupilsData.loading"
            @click="deletePupilsData.showingConfirmDialog = false"
          >
            Annuleren
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="deletePupilsData.loading"
            @click="onBtnDeletePupilsConfirm"
          >
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline, mdiAccountMultipleRemove, mdiQrcode, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiFileDocumentOutline, mdiInformationOutline, mdiPlus, mdiStickerTextOutline, mdiSquareEditOutline,
} from '@mdi/js'
import {
  computed, onUnmounted, ref, watch,
} from '@vue/composition-api'

// sidebar
import axios from '@axios'
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

export default {
  components: {
    UserListAddNew,
  },
  setup() {
    // -- USER STORING MODULE -- //
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // -- DATA -- //
    // Page data
    const classes = ref([])
    const school = JSON.parse(localStorage.getItem('school'))

    // Table meta
    const searchQuery = ref('')
    const selectedRows = ref([])
    const userListTable = ref([])
    const loading = ref(false)

    const tableColumns = ref([
      { text: 'Leerling', value: 'firstName' },
      { text: 'Klas', value: 'class', sortable: false },
      {
        text: 'Acties',
        value: 'actions',
        sortable: false,
      },
    ])

    // Filtering
    const filterClass = ref([])
    const filterName = ref('')

    // Add user dialog
    const isAddNewUserSidebarActive = ref(false)

    // Sticker printing
    const stickerPrinting = ref({
      showingWebsiteSelectDialog: false,
      websites: [],
      selectedWebsites: [],
    })

    // Delete user dialog
    const deletePupil = ref({
      showDeleteDialog: false,
      pupilToDelete: {},
    })

    // Delete multiple pupils data
    const deletePupilsData = ref({
      showingConfirmDialog: false,
      loading: false,
      progress: {
        total: 0,
        current: 0,
        percent: 0,
      },
      currentPupil: null,
    })

    // -- METHODS -- //
    // Fetch users
    function fetchUsers() {
      return new Promise((resolve, reject) => {
        loading.value = true
        axios
          .get(`/school/${school.id}/pupil`)
          .then(response => {
            const { pupils } = response.data
            userListTable.value = pupils

            // Remove loading state
            loading.value = false
          })
          .catch(error => reject(error))
      })
    }

    // Fetch websites
    function fetchWebsites() {
      axios.get('/website')
        .then(res => {
          stickerPrinting.value.websites = res.data.websites.map(website => {
            // eslint-disable-next-line no-param-reassign
            website.selected = false

            return website
          })

          stickerPrinting.value.websites = stickerPrinting.value.websites.filter(website => website.canBeUsedByStudents && website.enabled)
          console.log(stickerPrinting)
          console.log(stickerPrinting.value)
        })
    }

    // Get classes
    axios.get(`/school/${school.id}/class?simple=true`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = preparedClasses
    })

    // Print QR codes for selected pupils
    const printQrCodes = async () => {
      const usersWithQr = selectedRows.value

      const qrIds = usersWithQr.map(user => user.id)
      localStorage.removeItem('qrIds') // remove one item
      setTimeout(() => {
        localStorage.setItem('qrIds', JSON.stringify(qrIds))
        window.open('/print/qr', '_blank')
      }, 250)
    }

    // Print labels for selected pupils
    const printLabels = async () => {
      // Store selected users
      const usersWithQr = selectedRows.value
      const ldIds = usersWithQr.map(user => user.id)
      localStorage.setItem('ldIds', JSON.stringify(ldIds))

      // Store selected websites
      const { selectedWebsites } = stickerPrinting.value
      localStorage.setItem('selectedWebsites', JSON.stringify(selectedWebsites))

      window.open('/print/label', '_blank')
    }

    // Async foreach function that's required here
    async function asyncForEach(array, callback) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < array.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await callback(array[index], index, array)
      }
    }

    // Delete selected pupils
    const onBtnDeletePupilsConfirm = async () => {
      // const usersToDelete = selectedRows.value
      deletePupilsData.value.loading = true
      deletePupilsData.value.progress = {
        // basic stuff
        current: 0,
        total: selectedRows.value.length,
        percent: 0,

        // more complex stuff
        failed: 0,
        success: 0,
      }

      try {
        const progressData = deletePupilsData.value.progress

        // Loop through the users to delete them
        await asyncForEach(selectedRows.value, async pupil => {
          // eslint-disable-next-line no-plusplus
          progressData.current++
          progressData.percent = (progressData.current / progressData.total) * 100

          const response = await store.dispatch('app-user/deleteUser', pupil)

          if (response.status === 200 && response.data.success === true) {
            // eslint-disable-next-line no-plusplus
            progressData.success++
          } else {
            // eslint-disable-next-line no-plusplus
            progressData.failed++
          }
        })

        if (progressData.success === progressData.total) {
          toast('success', 'Top!', 'Alle gevraagde leerlingen zijn verwijderd.')
        } else if (progressData.failed !== progressData.total) {
          toast('error', 'Oei..', `Slechts ${progressData.success} leerling(en) zijn verwijderd`)
        } else {
          toast('error', 'Oei..', 'Er zijn geen leerlingen verwijderd')
        }
      } catch (e) {
        console.log(e)
        toast('error', 'Oei..', 'Er is iets misgelopen. Probeer het later opnieuw.')
      } finally {
        fetchUsers()
        deletePupilsData.value.loading = false
        deletePupilsData.value.showingConfirmDialog = false
        selectedRows.value = []
      }
    }

    // Function to show delete confirmation dialog
    function onBtnDeletePupilClick(pupil) {
      deletePupil.value.showDeleteDialog = true
      deletePupil.value.pupilToDelete = pupil
    }

    // Function to delete pupil upon confirmation
    function onBtnDeletePupilConfirm() {
      store.dispatch('app-user/deleteUser', deletePupil.value.pupilToDelete).then(() => {
        deletePupil.value.showDeleteDialog = false
        fetchUsers()
      })
    }

    // -- WATCHERS -- //
    // Watch for class filter change
    watch(filterClass, () => {
      selectedRows.value = []
    })

    // -- COMPUTED -- //
    // Filtered pupils
    const filteredPupils = computed(() => userListTable.value.filter(
      pupil => (`${pupil.firstName} ${pupil.lastName}`).toLowerCase().includes(filterName.value.toLowerCase()) && (filterClass.value.length === 0 || filterClass.value.includes(pupil.class.id)),
    ))

    // -- WATCHERS -- //
    watch([searchQuery], () => {
      selectedRows.value = []
      fetchUsers()
    })

    // Login methods
    const loginMethods = ref([])
    axios.get('/loginmethod').then(response => {
      loginMethods.value = response.data.loginMethods
    })

    fetchUsers()
    fetchWebsites()

    return {
      // -- Data -- //
      // Page data
      classes,

      // Table meta
      tableColumns,
      selectedRows,

      // Add user dialog
      isAddNewUserSidebarActive,

      // Sticker printing
      stickerPrinting,

      // Delete user dialog
      deletePupil,
      deletePupilsData,

      // -- Filtering -- //
      filterClass,
      filterName,

      // -- Computed -- //
      filteredPupils,
      loginMethods,

      // -- Methods -- //
      printQrCodes,
      printLabels,
      onBtnDeletePupilsConfirm,
      onBtnDeletePupilClick,
      onBtnDeletePupilConfirm,

      // -- User store module -- //
      loading,
      userListTable,
      avatarText,
      fetchUsers,

      // -- Icons -- //
      icons: {
        mdiSquareEditOutline,
        mdiAccountMultipleRemove,
        mdiQrcode,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiStickerTextOutline,
        mdiExportVariant,
        mdiAccountOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
