<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Nieuwe leerling toevoegen</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <!-- First name -->
          <v-text-field
            v-model="userData.firstName"
            outlined
            dense
            :rules="[validators.required]"
            label="Voornaam"
            placeholder=""
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Last name -->
          <v-text-field
            v-model="userData.lastName"
            outlined
            dense
            :rules="[validators.required]"
            label="Achternaam"
            placeholder=""
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!-- Class -->
          <v-select
            v-model="selectedClass"
            :items="classes"
            item-text="title"
            item-value="value"
            outlined
            dense
            :rules="[validators.required]"
            label="Klas"
            placeholder=""
            hide-details="auto"
            class="mb-6"
          ></v-select>

          <!-- Login method -->
          <v-select
            v-model="selectedLoginMethod"
            :rules="[validators.required]"
            label="Inlogmethode"
            :items="allowedLoginMethods"
            item-text="label"
            item-value="type"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>

          <div
            v-if="selectedLoginMethodObj"
          >
            <!-- Fields for the login method -->
            <v-text-field

              v-for="field in selectedLoginMethodObj.fields"
              :key="field.name"
              v-model="field.value"
              :rules="field.rules"
              outlined
              dense
              :label="field.label"
              placeholder=""
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </div>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Toevoegen
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetuserData"
          >
            Annuleren
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

function toast(status, title, text, duration = 3000) {
  Swal.fire({
    position: 'top-end',
    icon: status,
    title,
    text,
    showConfirmButton: false,
    timer: duration,
    toast: true,
  })
}

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    loginMethods: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
    }

    const selectedLoginMethod = ref(null)
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const allowedLoginMethods = ref([
      {
        type: 'password',
        label: 'Gebruikersnaam en wachtwoord',
        fields: [
          {
            name: 'username',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
          {
            name: 'password',
            label: 'Wachtwoord',
            type: 'password',
            rules: [
              v => !!v || 'Wachtwoord is verplicht',
            ],
            value: null,
          },
        ],
      },
      {
        type: 'qr',
        label: 'QR-code',
        fields: [],
      },
      {
        type: 'google',
        label: 'Google',
        fields: [
          {
            name: 'email',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
        ],
      },
      {
        type: 'microsoft',
        label: 'Microsoft',
        fields: [
          {
            name: 'email',
            label: 'E-mail adres',
            type: 'email',
            rules: [
              v => !!v || 'Email is verplicht',
              v => /.+@.+/.test(v) || 'Email moet een geldig emailadres zijn',
            ],
            value: null,
          },
        ],
      },
    ])

    // Computed properties
    const selectedLoginMethodObj = computed(() => allowedLoginMethods.value.find(method => method.type === selectedLoginMethod.value))

    // Classes
    const selectedClass = ref(null)
    const classes = ref([])
    const school = JSON.parse(localStorage.getItem('school'))
    axios.get(`/school/${school.id}/class?simple=true`).then(response => {
      const preparedClasses = response.data.classes.map(classItem => ({
        value: classItem.id,
        title: classItem.name,
      }))
      classes.value = [
        {
          value: 'none',
          title: 'Geen',
          disabled: true,
        },
        ...preparedClasses,
      ]
    })

    const saveLoginMethod = async account => {
      try {
        const data = {
          type: selectedLoginMethod.value,
          details: {},
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const field of selectedLoginMethodObj.value.fields) {
          data.details[field.name] = field.value
        }

        console.log(data)

        const response = await axios.put(`/account/${account.id}/loginmethod`, data)
        if (response.status === 200) {
          return true
        }

        return false
      } catch (error) {
        console.log(error)

        return false
      }
    }

    const onSubmit = () => {
      if (valid.value) {
        // Opslaan & sluiten
        store.dispatch('app-user/addUser', userData.value).then(response => {
          const { account } = response.data
          store.dispatch('app-user/addUserAsPupil', account).then(async () => {
            // Add to class
            const addedToClass = await axios.post(`/school/${school.id}/class/${selectedClass.value}/pupil/${account.id}`)
            if (!addedToClass.data.success) throw new Error('Could not add user to class')

            // Store login method
            const storedLoginMethod = await saveLoginMethod(account)
            if (!storedLoginMethod) {
              console.log(storedLoginMethod)
              toast('error', 'Fout', 'Er is iets fout gegaan bij het opslaan van de loginmethode')
            } else {
              toast('success', 'Gelukt', 'Gebruiker is toegevoegd')
            }
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            resetuserData()
          })
        })
      } else {
        validate()
      }
    }

    return {
      resetuserData,
      form,
      onSubmit,
      userData,
      valid,
      validate,

      selectedClass,
      classes,

      allowedLoginMethods,
      selectedLoginMethod,
      selectedLoginMethodObj,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
